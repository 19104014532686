import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>Not Found</h1>
    <p>Sorry, you've ended up in the wrong place!</p>
  </Layout>
)

export default NotFoundPage
